import expertDiagnosis from "../../assets/diagnosis_1.png";
import personalisedTherapy from "../../assets/personalisedTherapy.png";
import holisticSolution from "../../assets/HolisticSolution.png";

const WhyUs = () => {
  return (
    <section className="bg-[#373f61] py-12 text-white">
      <div className="container mx-auto text-center px-6 lg:px-12 xl:px-28 lg:mt-6">
        {/* Section Title */}
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
          Why Choose Us?
        </h2>
        <p className="text-base md:text-lg  text-gray-400 mb-8">
          Discover the Unique Benefits of Our Approach
        </p>

        {/* Flexbox Content */}
        <div className="flex flex-col lg:flex-row justify-evenly items-center lg:mb-6">
          {/* Expert Diagnosis */}
          <div className="flex flex-col items-center text-white p-4 max-w-xs lg:max-w-sm mb-8 lg:mb-0">
            <img
              src={expertDiagnosis}
              alt="Expert Diagnosis"
              className="mb-4 w-10 lg:w-20 sm:w-16"
            />
            <h3 className="text-lg md:text-xl font-semibold mb-3">
              Expert Diagnosis
            </h3>
            <p className="text-base text-gray-400 leading-relaxed">
              Leverage our advanced diagnostic tools to gain a clear
              understanding of your mental health needs.
            </p>
          </div>

          {/* Personalized Therapy */}
          <div className="flex flex-col items-center text-white p-4 max-w-xs lg:max-w-sm mb-8 lg:mb-0">
            <img
              src={personalisedTherapy}
              alt="Personalized Therapy"
              className="mb-4 w-10 lg:w-20 sm:w-16"
            />
            <h3 className="text-lg md:text-xl font-semibold mb-3">
              Personalized Therapy
            </h3>
            <p className="text-base text-gray-400 leading-relaxed">
              Get matched with experienced therapists who provide personalized
              care based on your unique needs.
            </p>
          </div>

          {/* Holistic Solutions */}
          <div className="flex flex-col items-center text-white p-4 max-w-xs lg:max-w-sm mb-8 lg:mb-0">
            <img
              src={holisticSolution}
              alt="Holistic Solutions"
              className="mb-4 w-10 lg:w-20 sm:w-16"
            />
            <h3 className="text-lg md:text-xl font-semibold mb-3">
              Holistic Solutions
            </h3>
            <p className="text-base text-gray-400 leading-relaxed">
              Access a variety of therapies and tools designed to support your
              mental well-being from multiple angles.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
