import React from "react";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import toast from "react-hot-toast"; // Assuming you are using react-hot-toast
import care from "../../assets/care.jpeg";

const Testimonial = () => {
  // Custom Next Arrow
  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="absolute right-0 md:right-0 md:-mr-2 xl:right-7 xl:-mr-8 top-1/2 transform -translate-y-1/2 bg-green-600 hover:bg-green-700 p-2 rounded-full text-white cursor-pointer z-10"
        onClick={onClick}
      >
        <FaArrowRight className="text-sm sm:text-lg" />
      </div>
    );
  };

  // Custom Prev Arrow
  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="absolute left-3 md:left-2 xl:left-5 xl:-ml-11 top-1/2 transform -translate-y-1/2 bg-green-600 hover:bg-green-700 p-2 rounded-full text-white cursor-pointer z-10"
        onClick={onClick}
      >
        <FaArrowLeft className="text-sm sm:text-lg" />
      </div>
    );
  };

  const testimonials = [
    {
      name: "Anonymous, Cabin Crew, Delhi",
      text: "I was sceptical and afraid to be open to another person. I thought I could not do it, but [my therapist] has been very supportive in terms of my mental health journey and just allows me to feel my emotions and simplifies them for me.",
    },
    {
      name: "Rahul, Software Engineer, Mumbai",
      text: "The platform has completely changed how I deal with stress. I used to struggle with anxiety, but after engaging in therapy sessions, I feel more at peace. My therapist listens and gives advice that truly works for me.",
    },
    {
      name: "Priya, Marketing Executive, Bangalore",
      text: "I was hesitant at first, but now I can’t imagine how I would have managed without their support. The tools provided here are so practical and helpful, and my therapist is genuinely caring.",
    },
    {
      name: "Ananya, Graphic Designer, Hyderabad",
      text: "What I love about this service is how personal everything feels. I’ve been working on my mental health for years, and this has been the most impactful experience. It’s helped me regain my confidence.",
    },
    {
      name: "Ravi, Business Analyst, Pune",
      text: "Being part of this platform has helped me more than I ever thought possible. The insights I’ve gained and the personalized care have made a world of difference in how I handle work-related stress.",
    },
    {
      name: "Sara, HR Manager, Chennai",
      text: "I cannot recommend this enough. My therapist has guided me through some very dark times, and now I feel much more in control of my emotions. The level of support is phenomenal.",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleConnectClick = () => {
    // Displaying a custom toast notification
    toast.custom((t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 p-4">
          <div className="flex items-start">
            <div className="ml-3 flex-1">
              <p className="text-sm font-medium text-gray-900">
                Feature In Progress
              </p>
              <p className="mt-1 text-sm text-gray-500">
                This feature is currently under development. Stay tuned for
                updates!
              </p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <button
            onClick={() => toast.dismiss(t.id)}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-darker hover:text-darkest focus:outline-none focus:ring-2 focus:ring-darker"
          >
            Close
          </button>
        </div>
      </div>
    ));
  };

  return (
    <div className="w-screen bg-[#fff7e9] py-8 lg:pb-20">
      {/* Testimonial Section */}
      <div className="w-11/12 lg:max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center space-y-12 sm:space-y-0">
        {/* Left Side: Heading */}
        <div className="lg:w-10/12 sm:w-1/2 text-left sm:mr-8">
          <h2 className="text-xl sm:text-2xl font-bold text-gray-800">
            Guiding countless individuals <br />
            to
            <span className="text-orange-500"> emotional wellness.</span>
          </h2>
          <p className="mt-4 text-sm sm:text-lg text-gray-600 lg:w-[70%]">
            Join us and take the first step towards a brighter future.
          </p>
        </div>

        {/* Right Side: Testimonial Slider */}
        <div className="w-full sm:w-1/2 relative">
          <Slider {...settings} className="relative px-10">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="flex justify-center">
                <div className="bg-gray-800 p-4 sm:p-6 lg:px-8 lg:py-9 rounded-3xl max-w-md text-white text-center lg:min-h-[200px] flex flex-col gap-4">
                  <p className="text-xs lg:text-sm">{testimonial.text}</p>
                  <p className="mt-2 font-semibold text-sm">
                    {testimonial.name}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Need Help Section */}
      <div className="w-11/12 lg:max-w-7xl mx-auto mt-12 py-10 px-4 sm:px-6 lg:px-6">
        <div className="grid lg:grid-cols-2 lg:gap-6">
          {/* Text part */}
          <div className="lg:w-full text-center lg:text-left">
            <h2 className="text-lg sm:text-xl lg:text-2xl font-bold text-gray-800 mb-4">
              Concerned about someone’s well-being?
            </h2>
            <p className="mt-2 text-sm lg:text-base text-gray-600 sm:text-md">
              Seeing someone you care about struggle can be difficult. Finding
              the right care is key. Our Care Consultant is here to guide you in
              getting the best support for yourself or a loved one.
            </p>
            <div className="mt-8 lg:mt-10">
              <button
                className="bg-[#f0703a] text-white px-5 py-2.5 rounded-md font-semibold hover:bg-[#cc6235]"
                onClick={handleConnectClick}
              >
                Connect with Us
              </button>
            </div>
          </div>
          {/* Image part */}
          <div className="flex justify-center mt-6 lg:mt-0">
            <img
              src={care}
              alt="Care Consultant"
              className="rounded-full w-52 lg:w-64"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
