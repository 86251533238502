import React from "react";
import logo from "../../assets/Logo.png";
import googlePlay from "../../assets/google-play.png"; // Placeholder for Google Play image
import appStore from "../../assets/App-store.png"; // Placeholder for App Store image
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
const notifyDownload = () =>
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">
              App Launching Soon!
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Stay tuned for the official app launch, coming to your favorite
              app stores soon.
            </p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-darker hover:text-darkest focus:outline-none focus:ring-2 focus:ring-darker"
        >
          Close
        </button>
      </div>
    </div>
  ));

const Footer = () => {
  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{ duration: 3000 }}
        reverseOrder={false}
      />
      <footer className="bg-[#373f61] py-8 border-t font-sora lg:py-16">
        <div className="max-w-7xl mx-auto px-4 text-sm lg:text-base">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* About ReZen */}
            <div className="flex flex-col items-center md:items-start">
              <h4 className="text-white font-semibold mb-4">ABOUT REZEN</h4>
              <ul className="text-center md:text-left">
                <li className="text-gray-100 mb-2">About Us</li>
                <li className="text-gray-100 mb-2">Careers</li>
                <li className="text-gray-100 mb-2">Media</li>
                <li className="text-gray-100 mb-2">For Therapists</li>
                <li className="text-gray-100 mb-2">Contact Us</li>
                <li className="text-gray-100 mb-2">FAQs</li>
              </ul>
            </div>

            {/* Services */}
            <div className="flex flex-col items-center md:items-start">
              <h4 className="text-white font-semibold mb-4">SERVICES</h4>
              <ul className="text-center md:text-left">
                <li className="text-gray-100 mb-2">Diagnosis and Therapy</li>
                <li className="text-gray-100 mb-2">Self-care and Progress</li>
                <li className="text-gray-100 mb-2">Community</li>
              </ul>
            </div>

            {/* Conditions */}
            <div className="flex flex-col items-center md:items-start">
              <h4 className="text-white font-semibold mb-4">CONDITIONS</h4>
              <ul className="text-center md:text-left">
                <li className="text-gray-100 mb-2">Depression</li>
                <li className="text-gray-100 mb-2">Addiction</li>
                <li className="text-gray-100 mb-2">Anxiety</li>
                <li className="text-gray-100 mb-2">OCD</li>
                <li className="text-gray-100 mb-2">Women Problem</li>
                <li className="text-gray-100 mb-2">Social Anxiety</li>
              </ul>
            </div>

            {/* Logo, Copyright, and App Buttons */}
            <div className="flex flex-col justify-center items-center md:items-start">
              {/* Logo */}
              <img
                src={logo}
                alt="ReZen Logo"
                className="mb-4 h-10 lg:h-14 w-auto"
              />
              <p className="text-gray-100 text-center md:text-left mb-4">
                &copy; 2024 ReZen. All rights reserved.
              </p>

              {/* App Store and Google Play Buttons */}
              <div className="flex space-x-4">
                {/* Google Play Button */}
                <button onClick={notifyDownload}>
                  <img
                    src={googlePlay}
                    alt="Download on Google Play"
                    className="h-11 w-auto"
                  />
                </button>
                {/* App Store Button */}
                <button onClick={notifyDownload}>
                  <img
                    src={appStore}
                    alt="Download on App Store"
                    className="h-12 w-auto"
                  />
                </button>
              </div>
              <div className="flex space-x-8">
                {/* Privacy Policy */}
                <Link
                  to="/privacy-policy"
                  className="text-gray-200 text-xs mt-2"
                >
                  <button className="hover:underline">Privacy Policy</button>
                </Link>
                <Link
                  to="/terms-and-conditions"
                  className="text-gray-200 text-xs mt-2"
                >
                  <button className="hover:underline">
                    Terms & Conditions
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
