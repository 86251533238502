import { useState } from "react";
import logo from "../../assets/Logo.png";
import { FaChevronDown, FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "../../index.css";
import toast, { Toaster } from "react-hot-toast";
const handleButtons = () => {
  // Displaying a custom toast notification
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">
              Feature Coming Soon!
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Stay tuned for updates!
            </p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-darker hover:text-darkest focus:outline-none focus:ring-2 focus:ring-darker"
        >
          Close
        </button>
      </div>
    </div>
  ));
};
const Navbar = () => {
  const [activeDropdown, setActiveDropdown] = useState(null); // Store which dropdown is active
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  let dropdownTimer = null;

  // Toggle dropdowns: only one at a time can be open
  const toggleDropdown = (dropdown) => {
    if (activeDropdown === dropdown) {
      setActiveDropdown(null); // Close if already open
    } else {
      setActiveDropdown(dropdown); // Open the clicked dropdown
    }
  };

  const handleMouseEnter = (dropdown) => {
    clearTimeout(dropdownTimer);
    setActiveDropdown(dropdown); // Open the hovered dropdown
  };

  const handleMouseLeave = () => {
    dropdownTimer = setTimeout(() => {
      setActiveDropdown(null); // Close after delay
    }, 100); // Timeout for smooth transition
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{ duration: 3000 }}
        reverseOrder={false}
      />
      <nav className="bg-[#fdf1db] font-sora">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="flex px-10 sm:px-14 md:px-20 lg:px-24 xl:px-32 justify-between items-center py-5 md:py-6"
        >
          {/* Logo */}
          <Link to="/" className="flex-shrink-0">
            <img src={logo} className="ml-2 h-8 md:h-10 lg:h-14" alt="Logo" />
          </Link>

          {/* Hamburger for Mobile */}
          <div className="lg:hidden">
            <button
              onClick={toggleMobileMenu}
              className="text-2xl text-[#cc6235]"
            >
              {mobileMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>

          {/* Nav Links for Large Screens */}
          <div className="hidden lg:flex lg:items-center lg:space-x-5 justify-end pl-5">
            {/* About Us Dropdown */}
            <div
              className="relative"
              onMouseEnter={() => handleMouseEnter("about")}
              onMouseLeave={handleMouseLeave}
            >
              <button
                onClick={() => toggleDropdown("about")}
                className="flex items-center text-[#cc6235] hover:text-[#f0703a] text-base font-semibold"
              >
                About Us
                <FaChevronDown
                  className={`ml-1 transform transition-transform duration-300 ${
                    activeDropdown === "about" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {activeDropdown === "about" && (
                <div className="absolute bg-white shadow-lg mt-2 rounded-lg w-56 p-4 space-y-3 text-base transition-opacity duration-200 ease-in-out">
                  <Link to="/">
                    <span className="block px-4 py-3 text-gray-600">Home</span>
                  </Link>
                  <Link to="/team">
                    <span className="block px-4 py-3 text-gray-600">Team</span>
                  </Link>
                  <Link to="/faq">
                    <span className="block px-4 py-3 text-gray-600">FAQs</span>
                  </Link>

                  <Link to="/contact-us">
                    <span className="block px-4 py-3 text-gray-600">
                      Contact Us
                    </span>
                  </Link>
                </div>
              )}
            </div>

            {/* Services Dropdown */}
            <div
              className="relative"
              onMouseEnter={() => handleMouseEnter("services")}
              onMouseLeave={handleMouseLeave}
            >
              <button
                onClick={() => toggleDropdown("services")}
                className="flex items-center text-[#cc6235] hover:text-[#f0703a] text-md font-semibold"
              >
                Services
                <FaChevronDown
                  className={`ml-1 transform transition-transform duration-300 ${
                    activeDropdown === "services" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {activeDropdown === "services" && (
                <div className="absolute bg-white shadow-lg mt-2 rounded-lg w-56 p-4 space-y-3 text-base transition-opacity duration-200 ease-in-out">
                  <Link to="/depression">
                    <span className="block px-4 py-3 text-gray-600">
                      Depression
                    </span>
                  </Link>
                  <Link to="/addiction">
                    <span className="block px-4 py-3 text-gray-600">
                      Addiction
                    </span>
                  </Link>

                  <Link to="/anxiety">
                    <span className="block px-4 py-3 text-gray-600">
                      Anxiety
                    </span>
                  </Link>
                  <Link to="/ocd">
                    <span className="block px-4 py-3 text-gray-600">OCD</span>
                  </Link>
                  <Link to="/women-problems">
                    <span className="block px-4 py-3 text-gray-600">
                      Women Problems
                    </span>
                  </Link>
                  <Link to="/social-anxiety">
                    <span className="block px-4 py-3 text-gray-600">
                      Social Anxiety
                    </span>
                  </Link>
                </div>
              )}
            </div>

            {/* Resources Dropdown */}
            <div
              className="relative"
              onMouseEnter={() => handleMouseEnter("resources")}
              onMouseLeave={handleMouseLeave}
            >
              <button
                onClick={() => toggleDropdown("resources")}
                className="flex items-center text-[#cc6235] hover:text-[#f0703a] text-md font-semibold"
              >
                Resources
                <FaChevronDown
                  className={`ml-1 transform transition-transform duration-300 ${
                    activeDropdown === "resources" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {activeDropdown === "resources" && (
                <div className="absolute bg-white shadow-lg mt-2 rounded-lg w-56 p-4 space-y-3 text-base transition-opacity duration-200 ease-in-out">
                  <Link to="/music">
                    <span className="block px-4 py-3 text-gray-600">Music</span>
                  </Link>
                  <Link to="/videos">
                    <span className="block px-4 py-3 text-gray-600">
                      Videos
                    </span>
                  </Link>
                  <Link to="/assessments">
                    <span className="block px-4 py-3 text-gray-600">
                      Assessment
                    </span>
                  </Link>
                  <button onClick={handleButtons}>
                    <span className="block px-4 pb-2 text-gray-600">
                      Exercises
                    </span>
                  </button>
                </div>
              )}
            </div>

            <Link
              to="/blogs"
              className="text-[#cc6235] hover:text-[#f0703a] font-semibold"
            >
              Blogs
            </Link>

            <Link
              to="/chatbot"
              className="text-[#cc6235] hover:text-[#f0703a] font-semibold"
            >
              Chatbot
            </Link>

            <div className="flex space-x-4">
              {/* Get Started Button */}
              <Link
                to="/signup"
                className="
      px-3 py-2 
      text-sm
      lg:px-4 lg:py-1.5 
      custom:px-5 custom:py-2 custom:text-base text-center
      bg-[#f0703a] 
      text-white 
      rounded 
      hover:bg-[#cc6235] 
      transition"
              >
                Get Started
              </Link>
              <Link
                to="/volunteer"
                className="px-3 py-2 lg:px-4 lg:py-1.5 
             border border-[#f0703a] 
             text-[#f0703a] 
             rounded 
             transition duration-300 
             hover:bg-[#f0703a] hover:text-white text-center"
              >
                Volunteer with Us
              </Link>
            </div>
          </div>
        </motion.div>

        {/* Mobile Menu */}
        {mobileMenuOpen && (
          <div className="lg:hidden bg-white shadow-lg w-full">
            <ul className="flex flex-col items-center space-y-4 py-4">
              {/* About Us */}
              <li>
                <button
                  onClick={() => toggleDropdown("about")}
                  className="text-[#cc6235] hover:text-[#f0703a] flex items-center"
                >
                  About Us
                  <FaChevronDown
                    className={`ml-1 transform transition-transform duration-300 ${
                      activeDropdown === "about" ? "rotate-180" : ""
                    }`}
                  />
                </button>
                {activeDropdown === "about" && (
                  <div className="mt-2 space-y-2">
                    <Link to="/">
                      {" "}
                      <span className="block px-4 py-2 text-gray-600">
                        Home
                      </span>{" "}
                    </Link>
                    <Link to="/team">
                      <span className="block px-4 py-2 text-gray-600">
                        Team
                      </span>
                    </Link>
                    <Link to="/faq">
                      <span className="block px-4 py-2 text-gray-600">
                        FAQs
                      </span>
                    </Link>
                    <Link to="/contact-us">
                      <span className="block px-4 py-2 text-gray-600">
                        Contact Us
                      </span>
                    </Link>
                  </div>
                )}
              </li>

              {/* Services */}
              <li>
                <button
                  onClick={() => toggleDropdown("services")}
                  className="text-[#cc6235] hover:text-[#f0703a] flex items-center"
                >
                  Services
                  <FaChevronDown
                    className={`ml-1 transform transition-transform duration-300 ${
                      activeDropdown === "services" ? "rotate-180" : ""
                    }`}
                  />
                </button>

                {activeDropdown === "services" && (
                  <div className="mt-2 space-y-2">
                    <Link to="/depression">
                      <span className="block px-4 py-2 text-gray-600">
                        Depression
                      </span>
                    </Link>
                    <Link to="/addiction">
                      <span className="block px-4 py-2 text-gray-600">
                        Addiction
                      </span>
                    </Link>
                    <Link to="/anxiety">
                      <span className="block px-4 py-2 text-gray-600">
                        Anxiety
                      </span>
                    </Link>
                    <Link to="/ocd">
                      <span className="block px-4 py-2 text-gray-600">OCD</span>
                    </Link>
                    <Link to="/women-problems">
                      <span className="block px-4 py-2 text-gray-600">
                        Women Problems
                      </span>
                    </Link>
                    <Link to="/social-anxiety">
                      <span className="block px-4 py-2 text-gray-600">
                        Social Anxiety
                      </span>
                    </Link>
                  </div>
                )}
              </li>

              {/* Resources */}
              <li>
                <button
                  onClick={() => toggleDropdown("resources")}
                  className="text-[#cc6235] hover:text-[#f0703a] flex items-center"
                >
                  Resources
                  <FaChevronDown
                    className={`ml-1 transform transition-transform duration-300 ${
                      activeDropdown === "resources" ? "rotate-180" : ""
                    }`}
                  />
                </button>
                {activeDropdown === "resources" && (
                  <div className="mt-2 space-y-2">
                    <Link to="/music">
                      <span className="block px-4 py-2 text-gray-600">
                        Music
                      </span>
                    </Link>
                    <Link to="/videos">
                      <span className="block px-4 py-2 text-gray-600">
                        Videos
                      </span>
                    </Link>
                    <Link to="/assessments">
                      <span className="block px-4 py-2 text-gray-600">
                        Assessment
                      </span>
                    </Link>
                    <button onClick={handleButtons}>
                      <span className="block px-4 py-2 text-gray-600">
                        Exercises
                      </span>
                    </button>
                  </div>
                )}
              </li>

              <li>
                <Link
                  to="/blogs"
                  className="text-[#cc6235] hover:text-[#f0703a] font-semibold"
                >
                  Blogs
                </Link>
              </li>

              <li>
                <Link
                  to="/chatbot"
                  className="text-[#cc6235] hover:text-[#f0703a] font-semibold"
                >
                  Chatbot
                </Link>
              </li>

              {/* Get Started Button */}
              <li>
                <Link
                  to="/signup"
                  className="
        px-3 py-2 
        text-sm 
        bg-[#f0703a] 
        text-white 
        rounded 
        hover:bg-[#cc6235] 
        transition"
                >
                  Get Started
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
